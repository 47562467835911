exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-crossfit-js": () => import("./../../../src/pages/crossfit.js" /* webpackChunkName: "component---src-pages-crossfit-js" */),
  "component---src-pages-crossfit-legendary-js": () => import("./../../../src/pages/crossfit_legendary.js" /* webpackChunkName: "component---src-pages-crossfit-legendary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions_legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-partenaires-js": () => import("./../../../src/pages/partenaires.js" /* webpackChunkName: "component---src-pages-partenaires-js" */),
  "component---src-pages-prochainement-js": () => import("./../../../src/pages/prochainement.js" /* webpackChunkName: "component---src-pages-prochainement-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article_template.js" /* webpackChunkName: "component---src-templates-article-template-js" */)
}

